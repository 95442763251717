/*
 * @Author: jiang
 * @Desctiption:  tools 工具集
 */
import date from './date'
import copy from './copy'
import merge, { arrayMergeByKey } from './merge'

export default {
  date,
  dayjs: date,
  copy,
  merge,
  arrayMergeByKey,
}
