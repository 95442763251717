<!--
 * @Author: jiang
 * @Date: 2021-06-13 23:33:00
 * @Description: 图片上传
-->
<template>
  <div class="c-upload-image">
    <div
      class="img-box"
      v-for="(img,index) in imgList"
      :key="img"
    >
      <el-image
        class="img"
        :src="img"
      ></el-image>
      <el-button
        class="btn"
        size="mini"
        @click="onRemove(index)"
      >删除</el-button>
    </div>

    <el-upload
    v-if="imgList.length<limit"
      ref="upload"
      action="false"
      list-type="picture-card"
      accept="image/*"
      :show-file-list="false"
      :http-request="onUpload"
      :limit="limit"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
  </div>
</template>

<script>
import { uploadImage } from '@/api/common/upload'
import { type } from 'jquery';
export default {
  props: {
    value: {
      type: Array,
    },
    limit:{
      type:Number,
      default:999
    }
  },
  data() {
    return {
      imgList: [],
    }
  },
  mounted(){
    console.log(this.imgList)
  },
  watch: {
    value: {
      handler(val) {
        this.imgList = val || []
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onUpload(data) {
      console.log()
      let that = this;
      uploadImage(data.file, progress => {
        if (progress.total > 0) {
          progress.percent = (progress.loaded / progress.total) * 100
        }
        data.onProgress(progress)
      }).then(res => {
        that.imgList.push(res.image_url)
        that.$emit('input', this.imgList)
      })
    },
    onRemove(index) {
      this.imgList.splice(index, 1)
      this.$emit('input', this.imgList)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-upload-image {
  display: flex;

  .img-box {
    position: relative;
    width: 148px;
    height: 148px;
    margin-right: 10px;

    .btn {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: none;
      width: 100%;
      background-color: rgba($color: #fff, $alpha: 0.7);
    }

    &:hover .btn {
      display: block;
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }
}
</style>
