<!--
 * @Author: jiang
 * @Date: 2021-06-13 21:07:10
 * @Description: 分页
-->
<template>
  <el-pagination
    style=" margin-top: 10px; text-align: right;"
    background
    hide-on-single-page
    layout="prev, pager, next, jumper, ->, total, slot"
    :current-page.sync="page.current"
    :page-size.sync="page.size"
    :total="page.total"
    @current-change="onChange"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
    },
  },
  methods: {
    onChange() {
      this.$emit('change')
    },
  },
}
</script>
