/**
 * 判断人名
 */
export default function (rule, value, callback) {
  const pattern = /^[\u4e00-\u9fa5]{2,4}$/
  let msg
  if (!pattern.test(value)) {
    msg = '请输入正确姓名'
  }

  if (msg) {
    callback(new Error(msg))
  } else {
    callback()
  }
}
