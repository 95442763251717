/*
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-07 14:41:11
 * @listEditTime: Do not edit
 */
const imgTextListState = {
    state: {
        imgTextList:
        {
            "name": "工作汇报 挂图作战 成绩成果成效",
            "type": "graphic-list",
            "dataSources":{
                "name":"category",
                "params":{
                    "id":""
                }
            },
            "mapping": {
                "title": "title",
                "src": "imageContent",
                "date": "releaseTime",
                "source": "deptName"
            }
        },
        bigPictureEdit:false,
        indexTemp:false,
      },

    getters: {
        imgTextList: state => state.imgTextList,
        bigPictureEditType: state => state.bigPictureEdit,
        indexTempType: state => state.indexTemp

    },
    mutations:{
        SET_IMG_TEXT_LIST: (state,data) =>{
            state.imgTextList.data = data
        },
        SET_BIG_TYOE:(state,data) =>{
            state.bigPictureEdit = data

        },
        SET_indexTemp_TYOE:(state,data) =>{
          state.indexTemp = data

      }
    },

    actions : {
        getAllImgList({ commit,data }) {
            commit('SET_IMG_TEXT_LIST')
        },
        setGlobalType({commit},data){
            commit("SET_BIG_TYOE",data)
        },

        setindexTempType({commit},data){
          commit("SET_indexTemp_TYOE",data)
      }
    }
}



export default imgTextListState
