/*
 * @Author: jiang
 */
import deepmerge from 'deepmerge'
import copy from './copy'

/**
 * 对象合并 merge()  merge.all()
 * @param { Object } target -
 * @param { Object } source -
 * @param { Object } options - 配置参数
 * @param { function } options.arrayMerge - 定义数组合并策略(默认后面的和前面的前面的和后面的拼接)
 * @param { function } options.isMergeableObject - 定义对象拷贝策略(原型属性)
 * @param { function } options.custommerge - 自定义合并策略
 * @param { boolean } options.clone - 默认 true
 */
const merge = deepmerge

export default merge

/**
 *
 * @param { object[] } target
 * @param { object[] } source
 * @param { string } [key]
 * @returns
 */
export function arrayMergeByKey(target, source, key = 'key') {
  // console.log('m', a, b, c)
  const arr = []
  const targetC = copy(target)
  const sourceC = copy(source)
  let message
  targetC.forEach(item => {
    if (item[key]) {
      const currentIndex = sourceC.findIndex(it => it[key] === item[key])
      if (currentIndex > -1) {
        arr.push(merge(item, sourceC.splice(currentIndex, 1)[0]))
      } else {
        arr.push(item)
      }
    } else {
      message = 'layout 中 必须设置 key'
    }
  })

  arr.push(...sourceC)

  if (message) {
    console.error(new Error(message))
    return
  }
  return arr
}

// const merge = require('deepmerge')

// const x = {
//   foo: { bar: 3 },
//   array: [{
//     does: 'work',
//     too: [1, 2, 3]
//   }]
// }

// const y = {
//   foo: { baz: 4 },
//   quux: 5,
//   array: [{
//     does: 'work',
//     too: [4, 5, 6]
//   }, {
//     really: 'yes'
//   }]
// }

// const x = [
//   {
//     a: 'x-a',
//     b: 'x-b'
//   }
// ]

// const y = [
//   {
//     a: 'y-a',
//     b: 'y-b'
//   }
// ]

// const res = merge(x, y)

// // const res = merge(
// //   [1, 2, 3],
// //   [3, 2, 1]
// // )
// console.log(res)
