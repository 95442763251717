<!--
 * @Author: jiang
 * @Date: 2021-08-05 10:58:59
 * @Description:
-->
<template>
  <el-button :type="type" :icon="icon" v-bind="$attrs" v-on="$listeners">
    <slot>{{ text }}</slot>
  </el-button>
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      id: 'create',
      type: 'primary',
      icon: 'el-icon-plus',
      text: '新增',
    }
  },
}
</script>
