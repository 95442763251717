<template>
  <div class="b-table">
    <!-- TODO 搜索 -->
    <search-form-view
      :option="option"
      @search="onSearch"
    >
      <template slot="searchBtn">
        <slot name="searchBtn"></slot>
      </template>
    </search-form-view>

    <!-- 操作栏 -->
    <!-- <div>
      <div>
        <el-button>d</el-button>
      </div>
      <div></div>
    </div> -->
    <!-- 表格前 slot -->
    <slot name="tableBefore"></slot>
    <!-- 表格 -->
    <el-table
      ref="bTable"
      :data="tableData"
      v-bind="option.tableAttrs"
      v-on="tableEvents"
    >
      <el-table-column
        v-for="item in tableColumns"
        :key="item.prop"
        :prop="item.prop"
        :label="item.label"
        v-bind="item.tableColumnAttrs"
      >
        <template
          v-for="slot in item.tableColumnSlots"
          :slot="slot"
          slot-scope="data"
        >
          <slot
            :name="'tableColumn' + item.prop.replace(item.prop[0], item.prop[0].toUpperCase()) + slot.replace(slot[0], slot[0].toUpperCase())"
            v-bind="data"
          ></slot>
        </template>
      </el-table-column>

      <!-- 操作栏 -->
      <el-table-column>
        <template slot-scope="scopeProps">
          <slot
            name="operation"
            v-bind="scopeProps"
          ></slot>
        </template>
      </el-table-column>

      <template
        v-for="item in option.tableSlots"
        :slot="item"
      >
        <slot :name="'table' + item.replace(item[0], item[0].toUpperCase())"></slot>
      </template>
    </el-table>

    <!-- 表格后 slot -->
    <slot name="tableAfter"></slot>

    <!-- 分页 -->
    <div
      v-if="page"
      class="g-mt-10"
    >
      <el-pagination
        :total="page.total"
        :page-size.sync="page.size"
        :current-page.sync="page.current"
        v-bind="pageAttrs"
        @current-change="onPageCurrentChange"
        @size-change="onPageSizeChange"
      >
        <slot name="page"></slot>
      </el-pagination>
    </div>

  </div>
</template>

<script>
import SearchFormView from '../form/index-search'
import { pageConfig, optionConfig } from '@/config/crud.config'
export default {
  components: {
    SearchFormView,
  },
  props: {
    option: {},
    page: {},
    tableData: {},
    tableEvents: {},
    searchData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSearchData: {},
    }
  },
  computed: {
    tableColumns() {
      const column = []
      this.option.columns.forEach(item => {
        if (item.tableShow !== false) {
          /**
           * 设置默认值
           * - table column 属性
           * - options 属性名转化
           * - 展示默认值
           */
          item.tableColumnAttrs = this.$tools.merge(
            optionConfig.columns[0].tableColumnAttrs || {},
            item.tableColumnAttrs || {},
          )
          item.optionsProp = this.$tools.merge(
            optionConfig.columns[0].optionsProp || { label: 'lable', value: 'value' },
            item.optionsProp || {},
          )
          item.defaultViewValue = item.defaultViewValue ?? optionConfig.columns[0].defaultViewValue

          /**
           * 结果值格式化
           * - radio
           * - checkbox
           * - 包含defaultViewValue 的普通类型
           */
          if (!item.tableColumnAttrs.formatter) {
            const prop = item.optionsProp
            let formatter
            if (item.type === 'radio') {
              formatter = row => {
                const value = row[item.prop]
                const current = item.options.find(it => it[prop.value] === value)
                if (current) {
                  return current[prop.label]
                } else {
                  console.error(`没有在 options 中找到 ${value} 对应的内容`)
                  return value ?? item.defaultViewValue
                }
              }
            } else if (item.type === 'checkbox') {
            } else if (item.defaultViewValue !== undefined && item.defaultViewValue !== null) {
              formatter = row => {
                const value = row[item.prop]
                return value ?? item.defaultViewValue
              }
            }

            if (formatter) {
              item.tableColumnAttrs.formatter = formatter
            }
          }

          column.push(item)
        }
      })
      return column
    },
    searchColumns() {
      const columns = []
      // const searchData
      this.option.columns.forEach(item => {
        const column = this.$tools.copy(item)
        if (item.search) {
          column.optionsProp = this.$tools.merge(
            optionConfig.columns[0].optionsProp || { label: 'lable', value: 'value' },
            item.optionsProp || {},
          )
          if (column.type === 'radio') {
            column.type = 'select'
          }
          columns.push(column)
          // this.searchData[column.prop] = ''
        }
      })

      return columns
    },
    pageAttrs() {
      const info = this.$tools.merge(pageConfig.attrs || {}, this.page.attrs || {})
      return info
    },
  },
  methods: {
    getTable() {
      return this.$refs.bTable
    },
    onPageCurrentChange() {
      this.$emit('page-change', this.page.current, this.page.size)
    },
    onPageSizeChange() {
      this.$emit('page-change', this.page.current, this.page.size)
    },
    onSearch(searchData) {
      console.log(searchData)
      this.$emit('search', searchData)
    },
    onReset() {
      this.$refs.searchForm.getForm().resetFields()
    },
  },
}
</script>

<style scoped>
.el-pagination {
  text-align: right;
}

.el-pagination >>> .el-pagination__rightwrapper {
  margin-left: 20px;
}
</style>
