<!--
 * @Author: jiang
 * @Date: 2021-08-05 11:05:06
 * @Description:
-->
<template>
  <el-tooltip
    :content="text"
    placement="top"
  >
    <el-button
      class="c-btn-table"
      :type="type"
      :icon="icon"
      plain
      v-bind="$attrs"
      v-on="$listeners"
    ></el-button>
  </el-tooltip>
</template>

<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      id: 'info',
      type: 'primary',
      icon: 'el-icon-view',
      text: '查看',
    }
  },
}
</script>

<style lang="scss" scoped>
.c-btn-table {
  padding: 10px 12px;
}
</style>
