import { getLocal, setLocal, getSession, setSession, clearStorage } from '@/store/storage'
import router from '@/router'
// import { Notification } from 'element-ui'
// let checkTimer = null

const user = {
  namespaced: true,
  state: {
    access_token: getSession('user_access_token'),
    userInfo: getLocal('user_info'),
  },
  getters: {
    id(state) {
      return state.userInfo.id
    },
    token(state) {
      return state.access_token
    },
    name(state) {
      return state.userInfo.name
    },
    deptId(state) {
      return state.userInfo.department.id
    },
    deptName(state) {
      return state.userInfo.department.name
    },
    isDeptAdmin(state) {
      return state.userInfo.department_admin === 1
    },
  },
  actions: {
    /**
     * 用户登录处理
     */
    USER_LOGIN({ commit }, info) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', info.api_token)
        commit('SET_USER_INFO', info)

        resolve()
      })
    },
    /**
     * 登出
     */
    LOGOUT({ dispatch }, routeName = 'login') {
      return dispatch('LOGOUT_CLEAR').then(() => {
        router.replace({ name: 'login' })
      })
    },
    /**
     * 清楚用户数据
     */
    LOGOUT_CLEAR({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', null)
        commit('SET_USER_INFO', {})

        commit('tag/CLEAR_TAG', null, { root: true })
        clearStorage()
        resolve()
      })
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.access_token = token
      setSession('user_access_token', state.access_token)
    },
    SET_USER_INFO(state, data) {
      state.userInfo = data
      setLocal('user_info', data)
    },
  },
}
export default user
