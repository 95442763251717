<template>
  <el-button :type="type" :icon="icon" plain v-bind="$attrs" v-on="$listeners">
    <slot>{{ text }}</slot>
  </el-button>
</template>

<script>
export default {
  inheritAttrs: false,

  data() {
    return {
      id: 'refresh',
      type: 'default',
      icon: 'el-icon-refresh-left',
      text: '刷新',
    }
  },
}
</script>
