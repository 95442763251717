import axios from 'axios'
import { httpCodeMessage } from '@/config/http.config'
import router from '@/router/index'
import { Message } from 'element-ui'
import qs from 'qs'
import store from '@/store'
import settingConfig from '@/config/index'
let hintMessage = null
let messageInstance = null

const request = axios.create({
  baseURL: settingConfig.baseURL,
  timeout: 120 * 1000,
  responseType: 'json',
  validateStatus(status) {
    return status >= 200 && status < 300
  },
  headers: {
    'Content-Type': 'application/json', // application/x-www-form-urlencoded  multipart/form-data
  },
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
  custom: {},
})

// const CancelToken = request.CancelToken

request.interceptors.request.use(
  config => {
    store.commit('LOADING_START')

    const isToken = (config.headers || {}).isToken === false
    if (!isToken) {
      const token = store.state.user.access_token
      config.headers.token = token
    }

    return config
  },
  error => {
    // 出现错误 可能 1.网络波动造成的 2.超时
    console.log('timeout11111', error)
    return Promise.reject(error)
  },
)

/**
 * 响应拦截 -- 明确错误信息
 */
request.interceptors.response.use(
  res => {
    store.commit('LOADING_DONE')
    if (res.data) {
      const data = res.data
      if (data.error_code && data.message) {
        let message = data.message
        if (data.error_code === 401001) {
          message = '您需要重新登录'
          store.dispatch('LOGOUT_CLEAR').then(() => {
            if (router.currentRoute.name !== 'login') {
              router.replace({ name: 'Login', query: { redirect: router.currentRoute.fullPath } })
            }
          })
        } else if (data.error_code === 422001) {
          message = data.message || '参数异常'
        }
        Message({
          message: message || httpCodeMessage.default,
          type: 'error',
        })
        return Promise.reject(new Error(message))
      }

      return data
    } else {
      return res
    }
  },
  error => {
    store.commit('LOADING_DONE')
    let message = ''

    if (axios.isCancel(error)) {
      /**
       * 取消请求
       */
      console.log('Request canceled', error.message)
    } else if (error.response) {
      /**
       * 请求被发出，服务器用状态码响应
       * console.log(error.response.data);
       * console.log(error.response.status);
       * console.log(error.response.headers);
       */
      const status = error.response.status
      message = httpCodeMessage[status] || httpCodeMessage.default

      // 提交参数错误，取出提示message
      if (status === 422) {
        if (typeof error.response.data.message === 'object') {
          message = error.response.data.message[Object.keys(error.response.data.message)[0]][0]
        }
      }

      // 权限问题则退出登录
      if (status === 401 || status === 426) {
        store.dispatch('user/LOGOUT_CLEAR').then(() => {
          if (router.currentRoute.name !== 'login') {
            router.replace({ name: 'login', query: { redirect: router.currentRoute.fullPath } })
          }
        })
      }
    } else if (error.request) {
      /**
       * 发出了请求但是没有收到回应
       */
      console.log(error.request)
      if (/^timeout of .* exceeded$/.test(error.message)) {
        message = '请求超时，请检查您的网络'
      }

      if (error.message === 'Network Error') {
        message = '网络错误，请检查您的网络连接'
      }
    } else {
      /**
       * 在设置请求的时候出现了错误
       */
      console.log(error.message)
    }

    // 连续相同的提示则关闭提示信息, // 如果出现， 并且当前提示信息和前一个提示信息一样则不显示
    if (message !== '' && !(messageInstance && hintMessage === message)) {
      hintMessage = message
      messageInstance = Message({
        message: message,
        type: 'error',
        showClose: true,
        onClose(instance) {
          // 相等表示是最后出现的提示
          if (instance === messageInstance) {
            messageInstance = null
          }
        },
      })
    }

    return Promise.reject(error)
  },
)

export default request
