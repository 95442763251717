import axios from '../axios'
import lrz from 'lrz'

//上传文件
export function uploadMedia(file) {
  console.log(file)
  const params = new FormData()
  params.append('file', file)

  return axios.request({
    method: 'post',
    url: '/api/users/mediaUploader',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: params,
  })
}

/**
 * 删除附件
 * @param { string } key
 * @returns
 */
export function removeMedia(key) {
  return axios({
    method: 'post',
    url: '/api/users/media/del',
    data: {
      mark: key,
    },
  })
}

//上传图片
export function uploadImage(file, callback = progress => {}) {
  return new Promise(resolve => {
    const imgEl = new Image()
    imgEl.src = URL.createObjectURL(file)
    imgEl.onload = function() {
      const option = {}
      const maxWidth = 1920
      const maxSize = 5 * 1000 * 1000
      let flag = false

      if (this.width > maxWidth) {
        option.width = maxWidth
        option.quality = 1
        flag = true
      }
      if (file.size > maxSize) {
        option.quality =1
        flag = true
      }
      if (flag) {
        lrz(file, option)
          .then(rst => {
            resolve(rst.file)
          })
          .catch(() => {
            resolve(file)
          })
      } else {
        resolve(file)
      }
    }
    imgEl.onerror = function() {
      resolve(file)
    }
  }).then(res => {
    const params = new FormData()
    params.append('image', res)

    return axios.request({
      method: 'post',
      url: '/api/users/image',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: params,
      onUploadProgress: callback,
    })
  })
}
