import Vue from 'vue'
import VueRouter from 'vue-router'
import { baseRoutes, errorRoutes } from './routes-common'

Vue.use(VueRouter)

const router = createRouter()

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.repalce = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

function createRouter() {
  return new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: baseRoutes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
  })
}

/**
 * 重置路由
 * @param { object[]} dynamicRoutes - 路由
 * @param { string } dynamicRoutes[].name - 名称
 * @param { string } dynamicRoutes[].path - 路由路径
 * @param { string } dynamicRoutes[].component - 组件路径
 * @param { object } dynamicRoutes[].meta - 组件路径
 */
export function resetRouter(dynamicRoutes = []) {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
  const routes = [
    ...dynamicRoutes.map(item => {
      return {
        path: item.path.startsWith('/') ? item.path : '/' + item.path,
        name: item.name,
        component: () => import('@/views/' + item.component + '.vue'),
        props: true,
        meta: item.meta,
      }
    }),
    ...errorRoutes,
  ]
  routes.forEach(item => {
    router.addRoute(item)
  })
}

export default router
