<!--
 * @Author: jiang
 * @Date: 2021-06-11 11:06:05
 * @Description:
-->

<template>
  <el-scrollbar y>
    <div class="b-page">
      <div class="g-p-10 g-bg">
        <slot></slot>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
export default {}
</script>
<style lang="scss" scoped>
.b-page {
  padding: 0 15px 15px;
}
</style>
