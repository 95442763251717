/*
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-11 16:42:01
 * @listEditTime: Do not edit
 */
import {clearStorage, getLocal, getSession, setLocal, setSession} from '@/store/storage'
import {dataSources} from "@/views/data/dataSources/dataSources";
import {jsonSources} from "@/views/data/dataSources/jsonSources";

import request from '@/api/axios';
import { DepartmentsDataStore,DepartmentsDataUpdate,DepartmentsDataSelect } from '@/api/depData'
import {getTaskCatelogList} from "@/api/task";
import {listToTree, treeForEach} from "@/utils/tree";
import Vue from "vue"
import {mapState} from "vuex";
// import { Notification } from 'element-ui'
// let checkTimer = null
import CryptoJS from "crypto-js";

const schema = {
  namespaced: true,
  state: {
    schema: getLocal('schema')||{},
    dataMap: getSession('dataMap')||{},
  },
  getters: {
    schema(state) {
      return state.schema
    },
    dataMap(state) {
      return state.dataMap
    },
    dataMapByKey: (state) => (dataSource) => {

      let key

        if (typeof dataSource === 'string') {
          // dataSource 是字符串
          key = dataSource;
        }else{
          if (dataSource && dataSource.name){
            const jsonString = JSON.stringify(dataSource.params);
            const hash = CryptoJS.MD5(jsonString).toString();

            key = dataSource.params&&dataSource.params.id?(dataSource.name+"-"+dataSource.params.id+"-"+(dataSource.params.column_id?dataSource.params.column_id:hash)):hash

          }
        }
        
        return state.dataMap[key];

    }
  },
  actions: {

    LoadJsonSource({commit}, dataSource){

      return new Promise((resolve, reject) => {
        if (dataSource.type === 'json') {
          const source = jsonSources[dataSource.name];
          let value =  source?.result(source.data) || source.data
          resolve(value)
        }
      })

    },
    async LoadDataSource({commit}, dataSource) {
     
      let key
      // if (dataSource.params&&dataSource.params.id){
      //   key=dataSource.name+dataSource.params.id
      // }else {
      //
      // }

      key=dataSource.name

      const source = dataSources[key];
      if (!source) {
        throw new Error(`Data source '${name}' not found`);
      }
   
      if (source.type == 'json') {
        //加载本地数据
        commit('SET_DATA_MAP', {
          key:  dataSource.name ,
          value: source.result(source.data) || source.data
        });
        return source.result(source.data) || source.data;
      }
    // ...mapState('user', {
    //     userId: state => state.userInfo.id,
    //     userName: state => state.userInfo.name,
    //     userDepartmentId: state => state.userInfo.department.id,
    //     userDepartmentName: state => state.userInfo.department.name,
    //   }),
    //
    //
    //     currentDepartment() {
    //     return this.$store.getters["schema/schema"].value
    //   },

      let depId=this.getters["schema/schema"].departments_id;
      let depIdtemp=depId;
      let params=source.params;
      // debugger
      if (depId<=2){
        //如果是领导部门，部门id都是空
        source.params.department_id=""
        dataSource.params.department_id=""
        params= dataSource.params;
      }else{
        if (dataSource.params){
          dataSource.params.department_id=depIdtemp
          params= dataSource.params;
        }else {
          source.params.department_id=depIdtemp
          params=source.params;
        }
      }


      //加载接口数据
     
      params.per_page = 50
      return await request({
        url: source.url,
        method: source.method,
        params: params
      }).then(res => {
        //debugger
        const jsonString = JSON.stringify(dataSource.params);
        const hash = CryptoJS.MD5(jsonString).toString();


        commit('SET_DATA_MAP', {
          key: dataSource.params&&dataSource.params.id?(dataSource.name+"-"+dataSource.params.id+"-"+(dataSource.params.column_id?dataSource.params.column_id:hash)):hash,
          value:
            source.result(res) || res.data,
            total:res.total
        });
        params.total = res.total
     
        return source.result(res) || res.data
      }).catch(err => {
        console.error(err)

      });

    },

    //更新创建 schema 存储到数据库中,如果数据库没有,自动创建
    // 传递的data为:{departments_name:xxx,value:为新的SCHEMA}
    async DepartmentsDataUpdate({commit}, data) {
      
      return DepartmentsDataUpdate(data).then(res => {
        commit('SET_SCHEMA', res.data);
        return res.data
      })
    },

    //将 schema 单纯新增存储到数据库中,不含更新操作
    // 传递的data为:{departments_name:xxx,value:为新的SCHEMA,departments_id:部门 id} ;(departments_id选填)
    async DepartmentsDataStore({commit}, data) {
      return DepartmentsDataStore(data).then(res => {
        //返回数据
        //   'departments_id' => $departmentsData->departments_id,
        //   'departments_name' => $departmentsData->departments_name,
        //   'value' => $departmentsData->value
        commit('SET_SCHEMA', res.data.value);//新数据返回回来
        return res.data;
      })
    },

    //通过 name 查询到 schema 存储到 vuex 中
    // 传递的data为:{departments_name:xxx}
    async DepartmentsDataSelect({commit}, data) {
      return await DepartmentsDataSelect(data).then(res => {
        commit('SET_SCHEMA', res.data);
        return res.data;
      })
    },

  },
  mutations: {
    SET_SCHEMA(state, schema) {
      console.log(schema)
       state.schema = schema
      //setLocal('schema', schema)
    },

    SET_DATA_MAP(state, data) {

      let mapKey = data.key;
      let mapValue =data.value

     // state.dataMap[mapKey] = mapValue
    

      Vue.set(state.dataMap,mapKey,mapValue)

     // setSession('dataMap', data)
    },
  },
}
export default schema
