/**
 * 广度优先遍历
 */
export function treeForEach(tree, func, prop = { children: 'children' }) {
  const list = [...tree]
  let node = list.shift()
  while (node) {
    func(node)
    node[prop.children] && list.push(...node[prop.children])
    node = list.shift()
  }
}

/**
 * 深度优先遍历 先序遍历
 */
export function treeForEach1(tree, func) {
  tree.forEach(node => {
    func(node)

    node.children && treeForEach1(node.children, func)
  })
}

/**
 * 深度优先遍历 后序遍历
 */
export function treeForEach2(tree, func) {
  tree.forEach(node => {
    node.children && treeForEach2(node.children, func)
    func(node)
  })
}

/**
 * 深度优先 先序遍历 循环
 */
export function treeForEach3(tree, func) {
  const list = [...tree]
  let node = list.shift()
  while (node) {
    func(node)
    node.children && list.unshift(...node.children)
    node = list.shift()
  }
}

/**
 * 列表转换成树结构
 */
export function listToTree(list, props = { parentId: 'parentId', id: 'id', children: 'children' }) {
  const info = list.reduce((map, node) => {
    
    map[node[props.id]] = node
    node[props.children] = []
    return map
  }, {})

  return list.filter(node => {
    info[node[props.parentId]] && info[node[[props.parentId]]][props.children].push(node)
    return !node[props.parentId]
  })
}

/**
 * 树结构 转成 列表结构
 */
export function treeToList(tree) {
  const result = tree.map(node => {
    node.level = 1
    return node
  })

  for (let i = 0; i < result.length; i++) {
    if (!result[i].children) continue
    const list = result[i].children.map(node => {
      node.level = result[i].level + 1
      return node
    })
    result.splice(i + 1, 0, ...list)
  }

  return result
}

/**
 * 树结构筛选
 */
export function treeFilter(tree, func, prop = { children: 'children' }) {
  return tree
    .map(node => ({ ...node }))
    .filter(node => {
      node[prop.children] = node[prop.children] && treeFilter(node[prop.children], func, prop)
      return func(node) ?? (node[prop.children] && node[prop.children].length)
    })
}

/**
 * 树结构查找
 */
export function treeFind(tree, func, props = { children: 'children' }) {
  for (const node of tree) {
    if (func(node)) return node
    if (node[props.children]) {
      const res = treeFind(node[props.children], func, props)
      if (res) return res
    }
  }
  return null
}

/**
 * 查找路径
 */
export function treeFindPath(tree, func, path = [], props = { id: 'id', children: 'children' }) {
  if (!tree) return []

  for (const node of tree) {
    path.push(node[props.id])
    if (func(node)) return path
    if (node[props.children]) {
      const findChildren = treeFindPath(node[props.children], func, path, props)
      if (findChildren.length) return findChildren
    }
    path.pop()
  }
  return []
}
