<!--
 * @Author: jiang
 * @Description:
    layouts
      -
        title:
        key: String
        slot: Boolean
        infoSlot:
        formSlot:
        children:
          -
            type: String
            label: String
            prop: String
            span: number
            slot: Boolean # infoLayout${key}
            emptyValue: any
            formatter: Function
            options: Array
    infoData
      -

  out
    slot
      - infoHeader
      - infoFooter
      - infoLayout${prop}
      - infoItem${prop}
-->

<template>
  <div class="b-info">
    <div class="b-info-header">
      <slot name="infoHeader"></slot>
    </div>

    <template v-for="sectionItem in sectionList">
      <section
        class="b-info__section"
        :key="sectionItem.key"
      >
        <!-- header -->
        <div
          class="b-info__section-header"
          v-if="sectionItem.title"
        >
          <span class="title">{{ sectionItem.title }}</span>
        </div>
        <!-- body -->
        <div class="b-info__section-body">

          <el-row :gutter="10">
            <el-col
              v-for="item in sectionItem.children"
              :key="item.prop"
              :span="item.span"
            >
              <info-item
                :label-width="item['label-width']"
                :label="item.label"
              >
                <slot
                  v-if="item.formItemViewSlot"
                  :name="'formItemView' + handlerCapitalizeFirst(item.prop)"
                  :row="formData"
                  :column="item"
                ></slot>
                <template v-else>
                  {{ handlerFormatValue(formData[item.prop], item) }}
                </template>
              </info-item>
            </el-col>
          </el-row>

          <slot :name="'infoLayout' + handlerCapitalizeFirst(sectionItem.key) "></slot>

          <!-- <div class="b-info__section__empty">empty
            </div> -->
        </div>
      </section>
    </template>

    <div class="b-info-footer">
      <slot name="infoFooter"></slot>
    </div>
  </div>
</template>

<script>
import InfoItem from './info-item'
export default {
  components: {
    InfoItem,
  },
  props: {
    option: {},
    formData: {},
  },
  data() {
    return {}
  },
  computed: {
    /**
     * 根据 *layout 布局信息创建; 默认使用 key=default，layout 和 infoLayout 对象合并并且 infoLayout 有较高的优先级
     * 拷贝元数据后操作，不影响元数据
     * 1. column 中指定的key 在 layout 中没有定义 则添加到 default 中
     * 2. column 未指定key添加到layout=default中
     * 3. layout 中没有定义 default 自动创建 一个 layout
     * {
     *  title: String
     *  key: String,
     *  slot: Boolean
     *  span: Number,
     *  show: String | Function
     *  children: {}[]
     * }
     */
    sectionList() {
      const defaultLayoutOption = {}
      const newLayout = []
      // const option = this.$tools.copy(this.option)

      // 布局配置合并
      let layouts = this.$tools.arrayMergeByKey(this.option.formLayouts || [], this.option.infoLayouts || [])
      if (layouts.findIndex((item) => item.key === 'default') === -1) {
        layouts.unshift({ key: 'default' })
      }
      layouts = layouts.map((item) => {
        return this.$tools.merge.all([defaultLayoutOption, item, { children: [] }])
      })
      const defaultLayout = layouts.find((it) => it.key === 'default')

      /**
       * 配置 column 参数
          label: String
          prop: String
          span: number
          infoSlot: Boolean
          emptyValue: any
          formatter: Function
       */
      this.option.columns.forEach((item) => {
        let flag = true // show infoshow
        item.show = item.infoShow ?? item.show
        if (typeof item.show === 'function') {
          flag = item.show(this.formData)
        } else if (item.show === false) {
          flag = false
        }
        if (flag) {
          let layout = layouts.find((it) => it.key === item.formLayoutKey)
          if (!layout) {
            layout = defaultLayout
          }
          item.span = item.infoSpan ?? item.span ?? layout.infoSpan ?? layout.span

          layout.children.push(item)
        }
      })

      layouts.forEach((item) => {
        let flag = true
        if (typeof item.show === 'function') {
          flag = item.show(this.formData)
        } else if (item.show === false) {
          flag = false
        }
        if (flag) {
          newLayout.push(this.$tools.merge.all([defaultLayoutOption, item, { children: [] }]))
        }
      })

      console.log('newLayout', newLayout)

      // layouts.filter((item) => (item.children && item.children.length > 0) || item.slot)

      return newLayout
    },
  },
  methods: {
    handlerFormatValue(value, column) {
      if (value === undefined || value === null) {
        return value ?? column.emptyValue
      }
      if (typeof column.formatter === 'function') {
        return column.formatter(this.formData, column)
      }
      if (column.type === 'radio') {
        const option = column.options.find((item) => item.value === value)
        return option.label
      }
      return value
    },
    handlerCapitalizeFirst(str) {
      return str.replace(str[0], str[0].toUpperCase())
    },
  },
}
</script>

<style lang="scss" scoped>
.b-info {
  color: #666;

  .b-info__section {
    margin-bottom: 20px;

    .b-info__section-header {
      height: 40px;
      padding: 0 15px;
      font-size: 16px;
      line-height: 40px;
      color: #000;
      background: #ecebeb;
    }

    .b-info__section-body {
      padding: 10px 30px;
      border: 1px solid #ecebeb;
    }
  }
  // .b-info__footer {}
  // .b-info__header {}
}
</style>
