export const httpCodeMessage = {
  '000': '操作太频繁，请勿重复请求',
  '401': '身份需要鉴权',
  '403': '当前操作没有权限',
  '404': '请求的资源不存在',
  '415': '上传的附件文件格式，不支持',
  '417': '未绑定登录账号，请使用密码登录后绑定',
  '422': '提交的参数有误，请检查参数',
  '423': '演示环境不能操作，如需了解联系我们',
  '426': '用户名不存在或密码错误',
  '428': '验证码错误,请重新输入',
  '429': '请求过频繁',
  '479': '演示环境，没有权限操作',
  '409': '保存失败，数据已经被其他人修改，请刷新页面获取最新数据。',
  '500': '服务器程序错误或者不可以',
  'default': '系统未知错误,请反馈给管理员',
}

// host

// errorCode

// codeMessage
