/*
 * @Author: jiang
 * @Date: 2021-06-11 18:59:29
 * @Description:
 */

import request from '../axios'
import { saveAs } from 'file-saver'
/**
 * 获取任务目录列表
 */
export function getTaskCatelogList() {
  return request({
    url: '/api/meetings',
  })
}

/**
 * 创建任务目录
 * @param { object } params
 * @param { string } params.name - 目录名称
 * @param { string } params.minutes - 目录说明
 * @param { string } params.parent_id - 父节点id
 */
export function createTaskCatelog(data) {
  return request({
    method: 'post',
    url: '/api/meetings',
    data,
  })
}

/**
 * 修改任务目录
 * @param { string } id
 * @param { object } params
 * @param { string } params.name
 * @param { string } params.parent_id
 */
export function updateTaskCatelog(id, params) {
  return request({
    method: 'post',
    url: `/api/meetings/${id}`,
    data: params,
  })
}

/**
 * 删除任务目录
 * @param { string } id
 */
export function deleteTaskCatelog(id) {
  return request({
    method: 'post',
    url: `/api/meetings/del/${id}`,
  })
}

/**
 * 获取任务
 * @param { string } [id] 目录id
 */
export function getTaskPage(params) {
  const cId = params.cId
  if (cId) {
    return request({
      url: `/api/meetings/${cId}/agendas`,
      params,
    })
  } else {
    return request({
      url: '/api/users/agendas',
      params,
    })
  }

  // return request({
  //   url: '/api/users/my/agendas',
  //   params: params,
  // })
}

/**
 * 部门任务统计
 */
export function getDeptTask() {
  return request({
    url: '/api/departments/agendas/count',
  })
}

/**
 * 获取子部门任务统计
 */
export function getDeptStatistics(id) {
  return request({
    url: '/api/feedback/departments/list',
    params: {
      department_id: id,
    },
  })
}

/**
 * 获取部门下人员任务统计
 */
export function getUserStatistics(id) {
  return request({
    url: '/api/feedback/users/list',
    params: {
      department_id: id,
    },
  })
}

/**
 * 任务详情
 * @param { string } id
 */
export function getTaskDetail(id) {
  return request({
    url: `/api/agendas/${id}`,
  })
}

export function getTasklist(state) {
  return request({
    url: `/api/agendas/?page=1&state=${state}&type=chu`,
  })
}
/**
 * 导出
 * @param { string } id
 */
export function exportTask(id) {
  const url = process.env.VUE_APP_API_HOST + `/api/agendas/${id}/export`
  saveAs(url)
}

/**
 * 创建任务
 */
export function createTask(data) {
  return request({
    method: 'post',
    url: '/api/agendas',
    data,
  })
}

/**
 * 修改任务
 */
export function updateTask(id, params) {
  return request({
    method: 'post',
    url: `/api/agendas/${id}`,
    data: params,
  })
}

/**
 * 删除任务
 */
export function deleteTask(id) {
  return request({
    method: 'post',
    url: `/api/agendas/del/${id}`,
  })
}

export function getUserTaskActionPage(params) {
  return request({
    method: 'get',
    url: '/api/my/actions/list',
    params,
  })
}
