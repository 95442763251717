/**
 * 判断手机号码
 */
export default function isPhone(rule, value, callback) {
  const pattern = /^1[3|4|5|6|7|8|9][0-9]{9}$/
  if (value.length === 11) {
    if (pattern.test(value)) {
      callback()
    } else {
      callback(new Error('手机号码格式不正确'))
    }
  } else {
    callback(new Error('手机号码长度不为11位'))
  }
}
