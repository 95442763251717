<!--
 * @Author: jiang
 * @Date: 2021-06-04 17:16:49
 * @Description:
-->

<template>
  <el-form
    ref="searchForm"
    :model="searchData"
    v-bind="option.searchFormAttrs"
    inline
  >
    <el-form-item
      v-for="item in itemList"
      :key="item.prop"
      :label="item.label"
      :prop="item.prop"
    >
      <template v-if="item.searchSlot">
        <slot :name="'ff' + item.prop"></slot>
      </template>
      <!-- select -->
      <template v-else-if="item.type === 'select'">
        <el-select
          v-model="searchData[item.prop]"
          v-bind="item.selectAttrs"
          v-on="item.selectEvents"
        >
          <el-option
            v-for="selectOption in item.selectOptions"
            :key="selectOption.value"
            :label="selectOption.label"
            :value="selectOption.value"
          ></el-option>
        </el-select>
      </template>
      <template v-else-if="item.type === 'input'">
        <el-input v-model="searchData[item.prop]"></el-input>
      </template>
    </el-form-item>
    <el-form-item style="vertical-align: bottom;">
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="onSearch"
      >查询</el-button>
      <el-button
        icon="el-icon-refresh-left"
        @click="onReset"
      >重置</el-button>
      <slot name="searchBtn"></slot>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
    },
  },
  data() {
    return {
      searchData: {},
    }
  },
  computed: {
    itemList() {
      const option = this.$tools.copy(this.option)
      const items = []
      option.columns.forEach((item) => {
        if (item.search) {
          items.push(item)
        }
      })

      return items
    },
  },
  methods: {
    onReset() {
      this.$refs.searchForm.resetFields()
      this.$emit('search', this.searchData)
    },
    onSearch() {
      this.$emit('search', this.searchData)
    },
  },
}
</script>
