module.exports = {
  /**
   * title 页面标题
   * @type String
   */
  title: '农牧厅精准监督',

  /*
   * 额外引入的css -
   * @type String
   */
  link: [],

  /*
   * 额外引入的js
   * Object / String
   */
  script: [],

  /**
   * 首页 - / 重定向
   */
  indexPage: '/',

  /*
   * 默认布局形式
   */
  defaultLayout: 'default',

  /**
   * 是否显示布局形式
   */
  layoutBtn: true,

  /*
   * 是否开启主题配置按钮
   */
  themeBtn: false,

  /**
   * 是否显示多标签页
   */
  tabsBar: true,

  /**
   * 显示日志按钮
   */
  logBtn: true,

  /**
   * axios baseURL
   */
  baseURL: process.env.VUE_APP_API_HOST,

  /**
   * 路由白名单
   */
  whiteRoutes: ['/login', '/regist', '/password',"/manager/office","/dp/index"],

  /**
   * 忽略的tag
   */
  whiteTagRoutes: ['/login', '/regist', '/password'],

  /**
   * 阿里字体图标库
   */
  confontUrl: '',
}
