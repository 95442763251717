import Vue from 'vue'
import router from './router'
import store from './store'
import './plugin/index'
import './utils/validator'
import './router/guard'
import App from './App.vue'

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
import dataV from '@jiaminghi/data-view'

import Fragment from 'vue-fragment';

import VueScroll from '@david-j/vue-j-scroll';

Vue.use(Fragment.Plugin);

Vue.use(dataV)

Vue.use(VueScroll);

//localStorage.setItem('user_info', {'api_token':"18865bb48c9e83e1"});

Vue.config.productionTip = false

/* eslint-disable */
var _hmt = _hmt || []
window._hmt = _hmt
;(function() {
  var hm = document.createElement('script')
  hm.src = 'https://hm.baidu.com/hm.js?36a9b171904cb0d8ca822b7342175d28'
  var s = document.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(hm, s)
})()

import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '9fe621bf4b3ff56a966bada6c1121027',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','AMap.ControlBar','AMap.MouseTool','AMap.GeometryUtil','AMap.DistrictSearch'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
  uiVersion:'1.0.11'
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
