/*
 * @Author: jiang
 * @Date: 2021-06-11 14:12:06
 * @Description:
 */

export const pageConfig = {}

export const optionConfig = {
  columns: [{}],
}
