/**
 * async-validator 验证扩展
 * https://github.com/yiminghe/async-validator
 */
import Vue from 'vue'
import isPhone from './phone-num'
import isPersonName from './person-name'

const validator = {
  isPhone,
  isPersonName,
}

Vue.prototype.$validator = validator
