<template>
  <el-dialog
    class="b-dialog"
    :class="{'b-dialog__has-title': !!$attrs.title}"
    :visible.sync="visible"
    :fullscreen="fullscreen"
    append-to-body
    v-bind="$attrs"
    :close-on-click-modal	= "false"
    v-on="$listeners"
  >
    <template slot="title">
      <slot name="header">
        <div>{{ $attrs.title}}</div>
      </slot>
      <button
        class="b-dialog__header-btn"
        @click="onFull"
      >
        <i class="el-icon-full-screen"></i>
      </button>
    </template>

    <slot></slot>

    <template slot="footer">
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      fullscreen: this.$attrs.fullscreen ?? false,
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val === this.visible) return
        this.visible = val
      },
      immediate: true,
    },
    visible(val) {
      if (val === this.value) return
      this.$emit('input', val)
    },
  },
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    onFull() {
      this.fullscreen = !this.fullscreen
    },
  },
}
</script>
<style lang="scss" scoped>
.b-dialog {
  .b-dialog__header-btn {
    position: absolute;
    top: 20px;
    right: 45px;
    padding: 0;
    font-size: 14px;
    color: #909399;
    cursor: pointer;
    background: 0 0;
    border: none;
    outline: 0;

    &:hover {
      color: $color-primary;
    }
  }

  &.b-dialog__has-title {
    /deep/ .el-dialog__header {
      border-bottom: 1px solid #f1f1f1;
    }
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
}
</style>
