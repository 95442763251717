import SessionKeystore from 'session-keystore'
import localStore from 'store'
import expirePlugin from 'store/plugins/expire'
import defaultsPlugin from 'store/plugins/defaults'
import defaults from './defaults'
localStore.addPlugin(expirePlugin)
localStore.addPlugin(defaultsPlugin)
localStore.defaults(defaults)
const sessionStore = new SessionKeystore()
const DEFAULT_NAMESPACE = 'DEFAULT_NAMESPACE_'

/**
 * 通过 key 查询 LocalStorage
 * @param { string } key
 * @returns any
 */
export function getLocal(key) {
  let value = localStore.get(key)

  if (value === undefined || value === null) {
    value = getDefault(key)
  }
  return value
}
/**
 * 设置 LocalStorage
 * @param { string } key
 * @param { any } value
 * @param { any } def
 * @param { number } expire 过期时间 秒
 */
export function setLocal(key, value, def, expire) {
  if (expire) {
    expire = Date.now() + parseInt(expire) * 1000
  }
  if (def !== undefined && def !== null) {
    setDefault(key, def)
  }
  localStore.set(key, value, expire)
}
/**
 * 移除单个 LocalStorage
 * @param { string } key
 */
export function removeLocal(key) {
  localStore.remove(key)
}

/**
 * 查询 SessionStorage
 * @param { string } key
 * @returns
 */
export function getSession(key) {
  let value = sessionStore.get(key)
  if (value === undefined || value === null) {
    value = getDefault(key)
  }
  return value
}

/**
 * 保存 SessionStorage
 * @param { string } key
 * @param { any } value
 * @param { any } def
 * @param { number } expire 过期时间 单位 秒
 */
export function setSession(key, value, def, expire) {
  if (expire) {
    expire = Date.now() + parseInt(expire) * 1000
  }
  if (def !== undefined && def !== null) {
    setDefault(key, def)
  }
  sessionStore.set(key, value, expire)
}
/**
 * 移除单个 sessionStorage 中的值
 * @param { string } key
 */
export function removeSession(key) {
  sessionStore.delete(key)
}

/**
 * 清楚所有存储数据 localStorage sessionStorage 和 默认值
 */
export function clearStorage() {
  localStore.clearAll()
  sessionStore.clear()
}

function setDefault(key, value) {
  key = DEFAULT_NAMESPACE + key
  localStore.set(key, value)
}

function getDefault(key) {
  key = DEFAULT_NAMESPACE + key
  return localStore.get(key)
}
